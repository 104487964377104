import React from "react";
import image from "../assets/image.png";
const AdmissionClosed = () => {
  return (
    <div className="acknowledgment h-screen flex flex-col items-center justify-center">
      <img src={image} alt="" className="md:w-[750px]" />
    </div>
  );
};

export default AdmissionClosed;
